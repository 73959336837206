import {
	AgencyDeal,
	AgencyDealFilter,
	AgencyDealManager,
	AgencyDealPreview,
	AgencyDealsColumn,
	BrandPreview,
	DealContract,
	DealDeliverable,
	DealDeliverablesSchema,
	DealEntry,
	DealEntryTalentTypeSchema,
	DealFile,
	DealInvoice,
	DealInvoiceStatus,
	DealOrder,
	DealOrderSchema,
	DealsLayout,
	DealsLayoutSchema,
	DealTask,
	DealTaskStatusSchema,
	TagColorOptionsSchema,
} from "@withjuly/fabric";
import { immer } from "zustand/middleware/immer";
import { createStore } from "zustand/vanilla";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealState = {
	deal: AgencyDeal;
	deals: AgencyDealPreview[];
	columns: AgencyDealsColumn[];
	filter: AgencyDealFilter | undefined;
	areFiltersEnabled: boolean | undefined;
	layout: DealsLayout;
	dealsOrder: DealOrder;
	isCommissionSplitEnabled: boolean | undefined;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type DealActions = {
	resetState: () => void;

	// Set Deal
	setDeal: (deal: AgencyDeal) => void;
	setDealUuid: (uuid: string) => void;
	setLayout: (layout: DealsLayout) => void;
	setDealsOrder: (order: DealOrder) => void;
	setIsCommissionSplitEnabled: (enabled: boolean) => void;

	// Status
	updateDealStatus: (columnUuid: string) => void;

	// Set text inputs
	setTitle: (title: string) => void;
	setBrandName: (name: string) => void;
	setNotes: (notes: string) => void;

	// Set Filter
	setFilter: (filter: AgencyDealFilter) => void;
	setFiltersEnabled: (enabled: boolean) => void;

	// Tasks
	addTask: (task: DealTask) => void;
	updateTask: (task: DealTask) => void;
	deleteTask: (uuid: string) => void;

	// Entries
	addEntry: (entry: DealEntry) => void;
	updateEntry: (entry: DealEntry) => void;
	deleteEntry: (uuid: string) => void;
	updateEntriesCommission: (commission: number | undefined) => void;
	updateDeliverableForEntry: (
		newItem: DealDeliverable,
		itemIndex: number,
		entryUuid: string,
	) => void;
	removeDeliverableForEntry: (itemIndex: number, entryUuid: string) => void;

	// Files
	addFile: (file: DealFile) => void;
	deleteFile: (uuid: string) => void;

	// Deals
	setDeals: (deals: AgencyDealPreview[]) => void;
	updateDeal: (deal: AgencyDeal) => void;
	deleteDeal: (uuid: string) => void;
	moveDealWithinColumn: (dealUuid: string, newOrder: number) => void;
	moveDealAcrossColumn: (
		dealUuid: string,
		overColumnUuid: string,
		insertIndex: number,
	) => void;
	updateDealPreview: (dealPreview: AgencyDealPreview) => void;

	// Temp Deal
	addTempDeal: (uuid: string, columnUuid: string, createdBy: string) => void;

	// Columns
	setColumns: (columns: AgencyDealsColumn[]) => void;
	addColumn: (column: AgencyDealsColumn) => void;
	deleteColumn: (uuid: string) => void;
	updateColumn: (column: AgencyDealsColumn) => void;

	// Managers
	updateManagers: (managers: AgencyDealManager[]) => void;
	updateManagersForDeal: (
		managers: AgencyDealManager[],
		dealUuid: string,
	) => void;

	// Contract
	updateContract: (contract: DealContract) => void;
	deleteContract: () => void;
	deleteContractFile: () => void;

	// Invoice
	updateInvoice: (invoice: DealInvoice) => void;
	deleteInvoice: () => void;
	deleteInvoiceFile: () => void;
	updateInvoiceStatus: (status: DealInvoiceStatus) => void;

	// Getters
	getOrderedTasks: () => DealTask[];
	getFilteredDeals: (
		columnUuid: string,
		agencyMembershipUuid: string | undefined,
	) => AgencyDealPreview[];

	// Brands
	setBrands: (brands: BrandPreview[]) => void;
};
export type DealStore = DealState & DealActions;

export const defaultDealState = {
	deal: {
		uuid: "",
		title: "",
		brandName: "",
		entries: [],
		tasks: [],
		notes: "",
		column: {
			uuid: "",
			title: "",
			tagColor: TagColorOptionsSchema.Enum.graphite,
		},
		files: [],
		managers: [],
		contract: undefined,
		invoice: undefined,
		brands: [],
	},
	deals: [],
	columns: [],
	filter: undefined,
	layout: DealsLayoutSchema.Enum.kanban,
	dealsOrder: DealOrderSchema.Enum.manual,
	areFiltersEnabled: false,
	isCommissionSplitEnabled: false,
};

export const createDealStore = (initState: DealState = defaultDealState) => {
	return createStore<DealStore>()(
		immer((set, get) => ({
			...initState,
			resetState: () => {
				set(() => defaultDealState);
			},
			setDeal: (deal) => {
				set((state) => {
					state.deal = deal;
				});
			},
			setDealUuid: (uuid) => {
				set((state) => {
					state.deal.uuid = uuid;
				});
			},
			setTitle: (title) => {
				set((state) => {
					state.deal.title = title;
				});
			},
			setBrandName: (name) => {
				set((state) => {
					state.deal.brandName = name;
				});
			},
			addTask: (task) => {
				set((state) => {
					state.deal.tasks.unshift(task);
				});
			},
			updateTask: (task) => {
				set((state) => {
					const updatedTasks = [...state.deal.tasks];
					const indexToEdit = updatedTasks.findIndex(
						(t) => t.uuid === task.uuid,
					);

					if (indexToEdit !== -1) {
						updatedTasks[indexToEdit] = task;
						updatedTasks.sort((a, b) =>
							a.status === DealTaskStatusSchema.Enum.incomplete &&
							b.status !== DealTaskStatusSchema.Enum.incomplete
								? -1
								: b.status === DealTaskStatusSchema.Enum.incomplete &&
									  a.status !== DealTaskStatusSchema.Enum.incomplete
									? 1
									: 0,
						);

						state.deal.tasks = updatedTasks;
					}
				});
			},
			deleteTask: (uuid) => {
				set((state) => {
					const indexToDelete = state.deal.tasks.findIndex(
						(task) => task.uuid === uuid,
					);
					if (indexToDelete !== -1) {
						state.deal.tasks.splice(indexToDelete, 1);
					}
				});
			},
			setNotes: (notes) => {
				set((state) => {
					state.deal.notes = notes;
				});
			},
			addEntry: (entry) => {
				set((state) => {
					state.deal.entries.unshift(entry);
				});
			},
			updateEntry: (entry) => {
				set((state) => {
					state.deal.entries = state.deal.entries.map((e) => {
						if (e.uuid === entry.uuid) {
							return entry;
						} else {
							return e;
						}
					});
				});
			},
			deleteEntry: (uuid) => {
				set((state) => {
					const indexToDelete = state.deal.entries.findIndex(
						(entry) => entry.uuid === uuid,
					);
					if (indexToDelete !== -1) {
						state.deal.entries.splice(indexToDelete, 1);
					}
				});
			},
			setDeals: (deals) => {
				set((state) => {
					state.deals = deals;
				});
			},

			updateDeal: (deal) => {
				set((state) => {
					state.deals = state.deals.map((d) => {
						if (d.uuid === deal.uuid) {
							const creators = deal.entries.map((entry) => {
								return {
									name:
										entry.talentType ===
											DealEntryTalentTypeSchema.Enum.roster && entry.talent
											? entry.talent.displayName
											: entry.talentName ?? "",
									profilePictureUrl: entry.talent?.profilePictureUrl,
									isCustom:
										entry.talentType === DealEntryTalentTypeSchema.Enum.custom,
								};
							});

							const deliverables = deal.entries.flatMap((entry) =>
								DealDeliverablesSchema.parse(entry.deliverables),
							);
							const totalAmount = deliverables.reduce((acc, item) => {
								return acc + (item.amount ?? 0);
							}, 0);

							const incompleteTasks = deal.tasks.filter(
								(task) => task.status === "incomplete",
							);

							const nextTask = incompleteTasks[0]
								? {
										uuid: incompleteTasks[0].uuid,
										title: incompleteTasks[0].title,
										status: incompleteTasks[0].status,
										dueDate: incompleteTasks[0].dueDate,
										color: incompleteTasks[0].color,
									}
								: undefined;

							return {
								uuid: deal.uuid,
								title: deal.title,
								column: deal.column,
								brandName: deal.brandName,
								creators: creators,
								allTasksCompleted:
									deal.tasks.length === 0
										? false
										: deal.tasks.every((task) => task.status === "complete"),
								deliverables: {
									count: deliverables.length,
									amount: totalAmount,
								},
								order: d.order,
								tasks: deal.tasks,
								createdBy: d.createdBy,
								managers: deal.managers,
								contractStatus: deal.contract
									? deal.contract.status
									: undefined,
								totalFiles: deal.files.length,
								nextTask: nextTask,
								createdAt: d.createdAt,
							};
						} else {
							return d;
						}
					});
				});
			},
			deleteDeal: (uuid) => {
				set((state) => {
					state.deals.splice(
						state.deals.findIndex((deal) => deal.uuid === uuid),
						1,
					);
				});
			},
			addTempDeal: (uuid, columnUuid, createdBy) => {
				set((state) => {
					const column = state.columns.find((col) => col.uuid === columnUuid);
					if (!column) {
						return;
					}

					return {
						deals: [
							{
								uuid: uuid,
								title: "New Deal",
								column: {
									uuid: columnUuid,
									title: "",
									tagColor: "graphite",
								},
								brandName: "",
								creators: [],
								allTasksCompleted: false,
								deliverables: {
									count: 0,
									amount: 0,
								},
								order: 0,
								tasks: [],
								nextTask: undefined,
								createdBy: createdBy,
								managers: [],
								totalFiles: 0,
								createdAt: new Date(),
							},
							...state.deals.map((deal) => ({
								...deal,
								order: deal.order + 1,
							})),
						],
					};
				});
			},
			setColumns: (columns) => {
				set((state) => {
					state.columns = columns;
				});
			},
			addColumn: (column) => {
				set((state) => {
					state.columns.push(column);
				});
			},
			deleteColumn: (uuid) => {
				set((state) => {
					state.columns.splice(
						state.columns.findIndex((col) => col.uuid === uuid),
						1,
					);
				});
			},
			updateColumn: (column) => {
				set((state) => {
					state.columns = state.columns.map((col) => {
						if (col.uuid === column.uuid) {
							return column;
						} else {
							return col;
						}
					});
				});
			},
			addFile: (file) => {
				set((state) => {
					state.deal.files.unshift(file);
				});
			},
			deleteFile: (uuid) => {
				set((state) => {
					state.deal.files.splice(
						state.deal.files.findIndex((file) => file.uuid === uuid),
						1,
					);
				});
			},
			updateManagers: (managers) => {
				set((state) => {
					state.deal.managers = managers;
				});
			},
			updateManagersForDeal: (managers, dealUuid) => {
				set((state) => {
					state.deals = state.deals.map((d) => {
						if (d.uuid === dealUuid) {
							return { ...d, managers: managers };
						} else {
							return d;
						}
					});
				});
			},
			updateEntriesCommission: (commission) => {
				set((state) => {
					state.deal.entries.forEach((entry) => {
						entry.managerCommission = commission;
					});
				});
			},
			deleteContract: () => {
				set((state) => {
					state.deal.contract = undefined;
				});
			},
			updateContract: (contract) => {
				set((state) => {
					state.deal.contract = contract;
				});
			},
			deleteContractFile: () => {
				set((state) => {
					if (state.deal.contract) {
						state.deal.contract.file = undefined;
					}
				});
			},
			updateDeliverableForEntry: (newItem, itemIndex, entryUuid) => {
				// set((state) => {
				// 	state.deal.entries.forEach((entry) => {
				// 		if (entry.uuid === entryUuid) {
				// 			entry.deliverables[itemIndex] = newItem;
				// 		}
				// 	});
				// });

				set((state) => {
					state.deal.entries = state.deal.entries.map((entry) =>
						entry.uuid === entryUuid
							? {
									...entry,
									deliverables: entry.deliverables.map((d, i) =>
										i === itemIndex ? newItem : d,
									),
								}
							: entry,
					);
				});
			},
			removeDeliverableForEntry: (itemIndex, entryUuid) => {
				set((state) => {
					state.deal.entries.forEach((entry) => {
						if (entry.uuid === entryUuid) {
							entry.deliverables.splice(itemIndex, 1);
						}
					});
				});
			},
			moveDealWithinColumn: (dealUuid, newOrder) => {
				set((state) => {
					const activeDeal = state.deals.find((deal) => deal.uuid === dealUuid);
					if (!activeDeal) return;

					const columnDeals = state.deals
						.filter((deal) => deal.column.uuid === activeDeal.column.uuid)
						.sort((a, b) => a.order - b.order);

					const currentIndex = columnDeals.findIndex(
						(deal) => deal.uuid === dealUuid,
					);
					if (currentIndex === -1) return;

					// Remove the deal from its original position
					const [movedDeal] = columnDeals.splice(currentIndex, 1);

					if (!movedDeal) return;
					// Insert at the new position
					columnDeals.splice(newOrder, 0, movedDeal);

					// Reassign order values
					columnDeals.forEach((deal, index) => {
						deal.order = index;
					});

					// Update the state without duplicating
					state.deals = state.deals.map((deal) =>
						deal.column.uuid === activeDeal.column.uuid
							? columnDeals.find((d) => d.uuid === deal.uuid) || deal
							: deal,
					);
				});
			},
			moveDealAcrossColumn: (dealUuid, overColumnUuid, insertIndex) => {
				set((state) => {
					const activeDeal = state.deals.find((deal) => deal.uuid === dealUuid);
					if (!activeDeal) return;

					// Get original column with sorted deals
					const originalColumnDeals = state.deals
						.filter((deal) => deal.column.uuid === activeDeal.column.uuid)
						.sort((a, b) => a.order - b.order);

					// Remove the deal from the original column
					originalColumnDeals.splice(
						originalColumnDeals.findIndex((deal) => deal.uuid === dealUuid),
						1,
					);

					// Reassign order values
					originalColumnDeals.forEach((deal, index) => {
						deal.order = index;
					});

					const overColumn = state.columns.find(
						(col) => col.uuid === overColumnUuid,
					);
					if (!overColumn) {
						return;
					}

					// Get deals in the over column
					const overColumnDeals = state.deals
						.filter((deal) => deal.column.uuid === overColumnUuid)
						.sort((a, b) => a.order - b.order);

					// Insert the moved deal at the correct position
					overColumnDeals.splice(insertIndex, 0, {
						...activeDeal,
						column: {
							...activeDeal.column,
							uuid: overColumnUuid,
						},
					});

					// Adjust order in new column
					overColumnDeals.forEach((deal, index) => {
						deal.order = index;
					});

					// Update state with new deals list
					state.deals = [
						...state.deals.filter(
							(deal) =>
								deal.column.uuid !== activeDeal.column.uuid &&
								deal.column.uuid !== overColumnUuid,
						),
						...originalColumnDeals,
						...overColumnDeals,
					];
				});
			},
			setFilter: (filter) => {
				set((state) => {
					state.filter = filter;
				});
			},
			getFilteredDeals: (columnUuid, agencyMembershipUuid) => {
				const { deals, filter, areFiltersEnabled, dealsOrder } = get();
				const columnDeals = deals
					.filter((deal) => deal.column.uuid === columnUuid)
					.filter((deal) => {
						if (!filter || !filter.createdByUuid || !areFiltersEnabled)
							return true;
						return deal.createdBy === filter.createdByUuid;
					})
					.filter((deal) => {
						if (areFiltersEnabled) return true;
						return deal.managers.some(
							(manager) => manager.uuid === agencyMembershipUuid,
						);
					});

				if (dealsOrder === DealOrderSchema.Enum.taskDueDate) {
					return columnDeals.sort((a, b) => {
						const dateA = a.nextTask?.dueDate?.getTime() ?? 0;
						const dateB = b.nextTask?.dueDate?.getTime() ?? 0;
						if (dateA && dateB) return dateA - dateB;
						if (dateA) return -1;
						if (dateB) return 1;
						return a.createdAt.getTime() - b.createdAt.getTime();
					});
				} else {
					return columnDeals.sort((a, b) => a.order - b.order);
				}
			},
			setFiltersEnabled: (enabled) => {
				set((state) => {
					state.areFiltersEnabled = enabled;
				});
			},
			setLayout: (layout) => {
				set((state) => {
					state.layout = layout;
				});
			},
			setDealsOrder: (order) => {
				set((state) => {
					state.dealsOrder = order;
				});
			},
			setIsCommissionSplitEnabled: (enabled) => {
				set((state) => {
					state.isCommissionSplitEnabled = enabled;
				});
			},
			updateDealStatus: (columnUuid) => {
				set((state) => {
					state.deal.column.uuid = columnUuid;

					const activeDeal = state.deals.find(
						(deal) => deal.uuid === state.deal.uuid,
					);
					if (!activeDeal) return;

					// Get original column with sorted deals
					const originalColumnDeals = state.deals
						.filter((deal) => deal.column.uuid === activeDeal.column.uuid)
						.sort((a, b) => a.order - b.order);

					// Remove the deal from the original column
					originalColumnDeals.splice(
						originalColumnDeals.findIndex(
							(deal) => deal.uuid === activeDeal.uuid,
						),
						1,
					);

					// Reassign order values
					originalColumnDeals.forEach((deal, index) => {
						deal.order = index;
					});

					// Get deals in new column
					const newColumnDeals = state.deals
						.filter((deal) => deal.column.uuid === columnUuid)
						.sort((a, b) => a.order - b.order);

					// Add to start of columnDeals
					newColumnDeals.unshift({
						...activeDeal,
						order: 0,
						column: {
							...activeDeal.column,
							uuid: columnUuid,
						},
					});

					// Increase order of deals in new column
					newColumnDeals.forEach((deal, index) => {
						deal.order = index;
					});

					state.deals = [
						...state.deals.filter(
							(deal) =>
								deal.column.uuid !== activeDeal.column.uuid &&
								deal.column.uuid !== columnUuid,
						),
						...originalColumnDeals,
						...newColumnDeals,
					];
				});
			},
			updateDealPreview: (dealPreview) => {
				set((state) => {
					state.deals = state.deals.map((deal) => {
						if (deal.uuid === dealPreview.uuid) {
							const incompleteTasks = dealPreview.tasks.filter(
								(task) => task.status === "incomplete",
							);

							const nextTask = incompleteTasks[0]
								? {
										uuid: incompleteTasks[0].uuid,
										title: incompleteTasks[0].title,
										status: incompleteTasks[0].status,
										dueDate: incompleteTasks[0].dueDate,
										color: incompleteTasks[0].color,
									}
								: undefined;

							return {
								...dealPreview,
								nextTask: nextTask,
							};
						} else {
							return deal;
						}
					});
				});
			},
			getOrderedTasks: () => {
				const { deal } = get();
				return [...deal.tasks].sort((a, b) =>
					a.status === DealTaskStatusSchema.Enum.incomplete &&
					b.status !== DealTaskStatusSchema.Enum.incomplete
						? -1
						: b.status === DealTaskStatusSchema.Enum.incomplete &&
							  a.status !== DealTaskStatusSchema.Enum.incomplete
							? 1
							: a.dueDate && b.dueDate
								? a.dueDate.getTime() - b.dueDate.getTime()
								: a.title.localeCompare(b.title),
				);
			},
			deleteInvoice: () => {
				set((state) => {
					state.deal.invoice = undefined;
				});
			},
			updateInvoice: (invoice) => {
				set((state) => {
					state.deal.invoice = invoice;
				});
			},
			deleteInvoiceFile: () => {
				set((state) => {
					if (state.deal.invoice) {
						state.deal.invoice.file = undefined;
					}
				});
			},
			updateInvoiceStatus: (status) => {
				set((state) => {
					if (state.deal.invoice) {
						state.deal.invoice.status = status;
					}
				});
			},
			setBrands: (brands) => {
				set((state) => {
					state.deal.brands = brands;
				});
			},
		})),
	);
};
